import React from 'react';
// import components
import Header from './components/Header';
import AnimRoutes from './components/AnimRoutes';
// import router
import { BrowserRouter as Router } from 'react-router-dom';
import SafeArea from 'react-safe-area-component'
// import motion
// import { motion } from 'framer-motion';
// import cursor context
// import { CursorContext } from './context/CursorContext';

const App = () => {
  // const { cursorVariants, cursorBG } = useContext(CursorContext);
  return (
    <>
    <SafeArea top />
      <Router>
        <Header />
        <AnimRoutes />
      </Router>
    <SafeArea bottom />

    </>
  );
};

export default App;
