import React, { useContext } from 'react';
// import components
import Socials from './Socials';
import Logo from '../img/header/cropped-logo.jpg';
import MobileNav from './MobileNav';
// import link
import { Link } from 'react-router-dom';
// import cursor context
import { CursorContext } from '../context/CursorContext';

const Header = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <header className='bg-white fixed w-full bg-white-200 px-[30px] lg:px-[100px] z-30 h-[100px] lg:h-[110px] flex items-center'>
      <div className='flex flex-col lg:flex-row lg:items-center w-full justify-between'>
        {/* logo */}

        <Link
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          to={'/'}
          className='  mb-0 lg: text-[26px] lg:text-[36px] font-primary mb-4'
        >
          <img src={Logo} alt='' className='h-[90px] overflow-hidden'/> 
          
        </Link>
        {/* nav - initially hidden - show on desktop mode */}
        <nav
          className='hidden lg:flex gap-x-12 font-semibold'
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <Link
            to={'/'}
            className='text-[#696c6d] hover:text-primary transition'
          >
            Home
          </Link>
          <Link
            to={'/about'}
            className='text-[#696c6d] hover:text-primary transition'
          >
            About
          </Link>
          <Link
            to={'/portfolio'}
            className='text-[#696c6d] hover:text-primary transition'
          >
            Porftolio
          </Link>
          <Link
            to={'/contact'}
            className='text-[#696c6d] hover:text-primary transition'
          >
            Contact
          </Link>
          {/* socials */}
          <Socials />
        </nav>
      </div>
      {/* mobile nav */}
      <MobileNav />
    </header>
  );
};

export default Header;
