import React, { useContext, useRef } from 'react';
// import images
import JacobImg from '../img/contact/jacob-with-phone-dark.png';
// import motion
import { motion } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';
// import context
import { CursorContext } from '../context/CursorContext';
// import emailjs
import emailjs from '@emailjs/browser';

const Contact = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  const [ isAlertVisible, setIsAlertVisible ] = React.useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_djx6b7j', 'template_y9t9i4b', form.current, 'm1pKnwZ50HPTnvDuR')
      .then((result) => {
          console.log(result.text);

          setIsAlertVisible(true);
          setTimeout(() => {
            setIsAlertVisible(false);
          }, 6000);
          form.current.reset()

      }, (error) => {
          console.log(error.text);
      });
      
  };
  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section bg-white'
    >
      <div className='no-scrollbar overflow-y-scroll lg:overflow-hidden container mx-auto h-full'>
        <div className='container flex flex-col lg:flex-row h-full items-center justify-start pt-28 gap-x-8 text-center lg:text-left'>
          {/* bg */}
          <motion.div
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={transition1}
            className=' lg:flex bg-[#f9df83] absolute inset-x-0 bottom-0 h-72 lg:h-3/5 -z-10'
          ></motion.div>
          {/* text & form */}
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='lg:flex-1  lg:pt-44 px-4'
          >
            <h1 className='h1'>Contact me</h1>
            <p className='mb-8'>I would love to connect with you.</p>
            {/* form */}
            <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-y-4'>
            <div className='flex gap-x-10'>
                <input
                  className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]'
                  type='text'
                  placeholder='first name'
                  name="user_name_first"
                  required
                />
                <input
                  className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]'
                  type='text'
                  placeholder='last name'
                  name="user_name_last"
                  required
                />
              </div>
              <div className='flex gap-x-10'>
                <input
                  className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]'
                  type='text'
                  placeholder='phone number'
                  name="phone_number"
                  required
                />
                <input
                  className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]'
                  type='email'
                  placeholder='email address'
                  name="user_email"
                  required
                />
              </div>
              <input
                className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]'
                type='text'
                placeholder='message'
                name="message"
                required
              />
              {!isAlertVisible && <button className='btn mb-[30px] mx-auto lg:mx-0 self-start'>
                Send it
              </button>}  
              {isAlertVisible && 
              <div className=''>
                <div className='h1 text-6xl'>Sent!</div>
              </div>}
            </form>
          </div>
          {/* image */}
          <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={{ transition: transition1, duration: 1.5 }}
            className=''
          >
          <img src={JacobImg} alt='' className='h-[300px] lg:h-[700px]'/>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;
